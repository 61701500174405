import { useQuery, useQueryClient } from '@tanstack/vue-query'
import { DateTime } from 'luxon'

export type Offer = NonNullable<Awaited<ReturnType<typeof fetchOfferBySlug>>['data']>

export function fetchOfferWithAppointmentsBySlug(
  slug: string,
  pagination: { page: number; itemsPerPage: number },
) {
  const supabase = useSupabase()

  return supabase
    .from('offer')
    .select('name, price, offer_appointment:offer_appointment(id, timestamp, remaining_slots)')
    .gt('offer_appointment.remaining_slots', 0) // filter used in Searchbar aswell
    .gt('offer_appointment.timestamp', DateTime.now().plus({ days: 1 }).startOf('day').toISO()) // filter used in Searchbar aswell
    .eq('slug_id', slug)
    .order('timestamp', { referencedTable: 'offer_appointment' })
    .range(
      (pagination.page - 1) * pagination.itemsPerPage,
      pagination.page * pagination.itemsPerPage - 1,
      { referencedTable: 'offer_appointment' },
    )
    .single()
}

export function useOfferWithAppointmentsBySlug(
  slug: MaybeRef<string>,
  pagination: MaybeRef<{ page: number; itemsPerPage: number }>,
) {
  return useQuery({
    queryKey: ['offer-with-appointments', slug, pagination] as const,
    queryFn: async ({ queryKey: [_, slugValue, paginationValue] }) =>
      fetchOfferWithAppointmentsBySlug(slugValue, paginationValue)
        .throwOnError()
        .then((res) => res.data),
  })
}

export function useOfferBySlug(slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ['offer', slug] as const,
    queryFn: async ({ queryKey }) =>
      fetchOfferBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}

export function fetchOfferBySlug(slug: string) {
  const supabase = useSupabase()

  return supabase
    .from('offer')
    .select(
      '*, location(id, name, slug, slug_id), artist(id, name, slug, slug_id), offer_type(id, name), cms_page_section(*)',
    )
    .eq('slug_id', slug)
    .order('order', {
      ascending: true,
      referencedTable: 'cms_page_section',
    })
    .single()
}

export function useOfferTypeById(id: MaybeRefOrGetter<string | undefined>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-type', toRef(id)],
    queryFn: async ({ queryKey }) => {
      const queryId = queryKey[1]

      if (queryId) {
        return supabase
          .from('offer_type')
          .select('id, name')
          .eq('id', queryId)
          .single()
          .throwOnError()
          .then((res) => res.data)
      } else {
        return { id: '', name: '-' }
      }
    },
  })
}

export function useOfferTypePreviews() {
  const queryClient = useQueryClient()
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-type-overview'],
    queryFn: async () =>
      supabase
        .from('offer_type')
        .select('id, name')
        .order('name')
        .throwOnError()
        .then((res) => {
          if (res.data)
            for (const offer of res.data) {
              queryClient.setQueryData(['offer-type', offer.id], offer)
            }

          return res.data
        }),
  })
}

export function useOfferBookingsByUserEMail(
  email: string,
  withPast: MaybeRef<boolean>,
  pagination: MaybeRef<{ page: number; itemsPerPage: number }>,
) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['offer-bookings', email, withPast, pagination] as const,
    queryFn: async ({ queryKey: [_, emailValue, withPastValue, paginationValue] }) => {
      let query = supabase
        .from('offer_booking')
        .select('*, offer_appointment!inner(timestamp, offer(name))', { count: 'exact' })
        .eq('user_email', emailValue)
        .order('created_at', { ascending: false })
        .range(
          (paginationValue.page - 1) * paginationValue.itemsPerPage,
          paginationValue.page * paginationValue.itemsPerPage - 1,
        )

      if (!withPastValue) {
        query = query.gt('offer_appointment.timestamp', new Date().toISOString())
      }

      return query.throwOnError()
    },
  })
}

export function fetchOfferPreviewBySlug(slug: string) {
  const supabase = useSupabase()

  return supabase.from('offer').select('*').eq('slug_id', slug).single()
}

export function useOfferPreviewBySlug(slug: MaybeRef<string>) {
  return useQuery({
    queryKey: ['offer-preview', slug] as const,
    queryFn: async ({ queryKey }) =>
      fetchOfferPreviewBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}

export function fetchOfferAppointmentBookingsBySlug(id: string) {
  const supabase = useSupabase()

  return supabase
    .from('offer_appointment')
    .select('*, offer_booking(*), offer(id, name)')
    .order('created_at', {
      ascending: false,
      referencedTable: 'offer_booking',
    })
    .eq('id', id)
    .single()
}

export function useOfferAppointmentBookingsBySlug(id: MaybeRef<string>) {
  return useQuery({
    queryKey: ['offer-appointment-bookings', id] as const,
    queryFn: async ({ queryKey }) =>
      fetchOfferAppointmentBookingsBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}
