import { useQuery } from '@tanstack/vue-query'

export type Artist = NonNullable<Awaited<ReturnType<typeof fetchArtistBySlug>>['data']>

export function useArtistBySlug(slugId: MaybeRefOrGetter<string>) {
  return useQuery({
    queryKey: ['artist', toRef(slugId)] as const,
    queryFn: async ({ queryKey }) =>
      fetchArtistBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}

export function fetchArtistBySlug(slugId: string) {
  const supabase = useSupabase()

  return supabase
    .from('artist')
    .select('*, cms_page_section(*), address(*)')
    .eq('slug_id', slugId)
    .order('order', {
      ascending: true,
      referencedTable: 'cms_page_section',
    })
    .single()
}

export function useArtistPreviews() {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['artists-overview'],
    queryFn: async () =>
      supabase
        .from('artist')
        .select('id, name, image_url, slug, slug_id')
        .order('name')
        .throwOnError()
        .then((res) => res.data),
  })
}
