<script lang="ts" setup>
onMounted(() => {
  // eslint-disable-next-line no-console
  console.log(`Current Atelierluft version: ${useRuntimeConfig().public.version}`)
})

provideHeadlessUseId(() => useId())
</script>

<template>
  <NuxtLayout class="h-full">
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
</template>
