import {
  BrowserTracing,
  Replay,
  captureException,
  init,
  vueRouterInstrumentation,
  withScope,
} from '@sentry/vue'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup(nuxt) {
    if (import.meta.dev) return

    const config = useRuntimeConfig()

    init({
      Vue: nuxt.vueApp,
      dsn: config.public.sentryDsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: vueRouterInstrumentation(nuxt.vueApp.$nuxt.$router, {
            routeLabel: 'path',
          }),
        }),
        new Replay(),
      ],
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    })

    nuxt.vueApp.config.errorHandler = (err, context) => {
      withScope((scope) => {
        scope.setExtra('context', context)
        captureException(err)
      })
    }

    nuxt.hook('app:error', (err) => {
      captureException(err)
    })
  },
})
