/* eslint eslint-comments/no-use: off */

import { hash } from 'ohash'

import type { Database } from '../supabase.db'
import type { Session } from '@supabase/supabase-js'

export function useSupabase() {
  return useSupabaseClient<Database>()
}

interface SupabaseQueryRealtimeOptions<
  T extends keyof Database['public']['Tables'],
  K extends keyof Database['public']['Tables'][T]['Row'],
> {
  event?: '*' | 'INSERT' | 'UPDATE' | 'DELETE'
  table: T
  filter?: [
    K,
    'eq' | 'neq' | 'lt' | 'lte' | 'gt' | 'gte' | 'in',
    string | number | string[] | number[],
  ]
}

export function buildSupabaseFilter(filter: SupabaseQueryRealtimeOptions<any, any>['filter']) {
  if (!filter) return

  // eslint-disable-next-line ts/no-unsafe-assignment
  const [key, operator, value] = filter
  if (operator === 'in' || Array.isArray(value)) {
    const val = Array.isArray(value) ? value : [value]
    return `${key}=${operator}.(${val.join(', ')})`
  }

  return `${key}=${operator}.${value}`
}

export function useSupabaseRealtime<
  T extends keyof Database['public']['Tables'],
  K extends keyof Database['public']['Tables'][T]['Row'],
>(
  opts: SupabaseQueryRealtimeOptions<T, K> | SupabaseQueryRealtimeOptions<T, K>[],
  callback: () => void,
) {
  const supabase = useSupabase()
  const channelKey = hash(opts)
  const subscription = supabase.channel(`realtime:database-changes:${channelKey}`)

  onMounted(() => {
    const optionsArray = Array.isArray(opts) ? opts : [opts]

    for (const options of optionsArray) {
      subscription.on(
        'postgres_changes',
        {
          // eslint-disable-next-line ts/no-unsafe-assignment
          event: (options.event as any) ?? '*',
          schema: 'public',
          table: options.table,
          filter: buildSupabaseFilter(options.filter),
        },
        callback,
      )
    }

    subscription.subscribe()
  })

  onUnmounted(async () => {
    if (subscription) return subscription.unsubscribe()
  })

  return async () => subscription.unsubscribe()
}

export function useUser() {
  // eslint-disable-next-line ts/no-unnecessary-type-assertion
  return useSupabaseUser() as Ref<Session['user']>
}
