import superjson from 'superjson'
import { createTRPCNuxtClient, httpBatchLink } from 'trpc-nuxt/client'

import type { AppRouter } from '~/server/trpc/routers'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup() {
    const trpc = createTRPCNuxtClient<AppRouter>({
      links: [
        httpBatchLink({
          url: '/api/trpc',
        }),
      ],
      transformer: superjson,
    })
    return {
      provide: {
        trpc,
      },
    }
  },
})
