import markerSDK from '@marker.io/browser'

export default defineNuxtPlugin(async () => {
  if (import.meta.dev) return {}

  const widget = await markerSDK.loadWidget({
    project: '652e8b5e24bf4e04a7c1fa6e',
  })

  return {
    provide: {
      marker: widget,
    },
  }
})
