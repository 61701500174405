import { useQuery, useQueryClient } from '@tanstack/vue-query'

export type Location = NonNullable<Awaited<ReturnType<typeof fetchLocationBySlug>>['data']>

export function useLocationBySlug(slugId: MaybeRef<string>) {
  return useQuery({
    queryKey: ['location', slugId] as const,
    queryFn: async ({ queryKey }) =>
      fetchLocationBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}

export function fetchLocationBySlug(slugId: string) {
  const supabase = useSupabase()

  return supabase
    .from('location')
    .select('*, cms_page_section(*), address(*)')
    .eq('slug_id', slugId)
    .order('order', {
      ascending: true,
      referencedTable: 'cms_page_section',
    })
    .single()
}

export function useLocationById(id: MaybeRefOrGetter<string | undefined | null>) {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['location', toRef(id)],
    queryFn: async ({ queryKey }) => {
      const queryId = queryKey[1]

      if (queryId) {
        return supabase
          .from('location')
          .select('id, name, slug, slug_id')
          .eq('id', queryId)
          .single()
          .throwOnError()
          .then((res) => res.data)
      } else {
        return { id: '', name: '-', slug: '', slug_id: '' }
      }
    },
  })
}

export function useLocationPreviews() {
  const queryClient = useQueryClient()
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['location-overview'],
    queryFn: async () =>
      supabase
        .from('location')
        .select('id, name, image_url, slug, slug_id')
        .order('name')
        .throwOnError()
        .then((res) => {
          if (res.data)
            for (const location of res.data) {
              queryClient.setQueryData(['location', location.id], location)
            }

          return res.data
        }),
  })
}
