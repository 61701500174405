<script lang="ts" setup>
import type { NuxtError } from 'nuxt/app'

const props = defineProps<{
  error: NuxtError
}>()

const is404 = computed(() => props.error.statusCode === 404)

watch(
  () => props.error,
  (err) => console.debug(err),
  { immediate: true },
)

function clear() {
  void clearError({
    redirect: '/',
  })
}
</script>

<template>
  <div class="flex h-screen flex-col justify-center">
    <div class="relative flex flex-col items-center">
      <div class="absolute -z-10 w-full overflow-x-hidden">
        <img
          :src="is404 ? '/svg/not-found.svg' : '/svg/error.svg'"
          class="object-cover"
          :class="
            is404
              ? 'h-[clamp(5rem,20vw,15rem)] w-[min(100%,2626px)] object-[35%]'
              : 'h-[clamp(10rem,40vw,30rem)] w-[min(100%,2602px)] object-[40%]'
          "
        />
      </div>
      <div :class="is404 ? 'h-[clamp(5rem,20vw,15rem)]' : 'h-[clamp(10rem,40vw,30rem)]'" />
      <AlPadding class="mt-8 flex flex-col gap-4">
        <p class="text-2xl sm:text-3xl">
          <span class="font-bold">
            {{
              is404
                ? 'Da ist wohl ein Kunstwerk verloren gegangen!'
                : 'Es scheint, als hätte unser künstlerischer Geist eine kleine Auszeit genommen.'
            }}
          </span>
          <br />
          {{
            is404
              ? 'Setze hier deine kreative Reise fort.'
              : 'Wir arbeiten daran, die Seite wiederherzustellen. Probier es später noch einmal.'
          }}
        </p>
        <AlSlideoutButton variant="secondary" @click="() => clear()">
          Zurück zur Startseite
        </AlSlideoutButton>
      </AlPadding>
    </div>
  </div>
</template>
