import { checkUserRoutePermissions } from '~/utils/auth'

export default defineNuxtPlugin(() => {
  const user = useUser()
  const route = useRoute()

  watch(
    user,
    async (newUser) => {
      return checkUserRoutePermissions(route, newUser)
    },
    { immediate: true },
  )
})
