import { default as _91legal_93IkzzjCWHDbMeta } from "/app/pages/[legal].vue?macro=true";
import { default as _91slug_93jOEkUlxx4DMeta } from "/app/pages/admin/angebot/[slug].vue?macro=true";
import { default as angeboteGwaSPjb3nRMeta } from "/app/pages/admin/angebote.vue?macro=true";
import { default as angebots_45buchungen52QBQ9pil6Meta } from "/app/pages/admin/angebots-buchungen.vue?macro=true";
import { default as _91slug_93Cl40UnF9PfMeta } from "/app/pages/admin/angebots-termine/[slug].vue?macro=true";
import { default as angebotsarten_45und_45kunstformenLFLNiClhUjMeta } from "/app/pages/admin/angebotsarten-und-kunstformen.vue?macro=true";
import { default as indexi8nCATZISJMeta } from "/app/pages/admin/index.vue?macro=true";
import { default as _91slug_93doGLKyNMOsMeta } from "/app/pages/admin/kuenstler-in/[slug].vue?macro=true";
import { default as kuenstler_45innenv84WGKq0PDMeta } from "/app/pages/admin/kuenstler-innen.vue?macro=true";
import { default as kunstraeume5XdV3mTwOxMeta } from "/app/pages/admin/kunstraeume.vue?macro=true";
import { default as _91slug_937AHS1VyE0lMeta } from "/app/pages/admin/kunstraum/[slug].vue?macro=true";
import { default as _91type_934VbJNUA5cqMeta } from "/app/pages/admin/landing/[type].vue?macro=true";
import { default as medien108iS9u5b5Meta } from "/app/pages/admin/medien.vue?macro=true";
import { default as _91slug_93iqyPrFmYbrMeta } from "/app/pages/admin/region/[slug].vue?macro=true";
import { default as regionen8SS1w5uwBYMeta } from "/app/pages/admin/regionen.vue?macro=true";
import { default as _91id_93RuYGxPCblsMeta } from "/app/pages/admin/termin-buchungen/[id].vue?macro=true";
import { default as admin0eiku7Jj1aMeta } from "/app/pages/admin.vue?macro=true";
import { default as _91slug_93qSYkVjzTIjMeta } from "/app/pages/angebot/[slug].vue?macro=true";
import { default as anmelden1zhaVHyhM6Meta } from "/app/pages/anmelden.vue?macro=true";
import { default as indexg58NvgAgO8Meta } from "/app/pages/buchung/index.vue?macro=true";
import { default as uebersicht5rdJ55PmfWMeta } from "/app/pages/buchung/uebersicht.vue?macro=true";
import { default as dankeO4KVv6EOejMeta } from "/app/pages/danke.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as kitchensinkthR9MqYzRIMeta } from "/app/pages/kitchensink.vue?macro=true";
import { default as _91slug_93ulmtor5kH4Meta } from "/app/pages/kuenstler-in/[slug].vue?macro=true";
import { default as _91slug_935HwWR9igluMeta } from "/app/pages/kunstraum/[slug].vue?macro=true";
import { default as _91slug_93G8e6QOqUrbMeta } from "/app/pages/region/[slug].vue?macro=true";
import { default as indexp9V2p5fxycMeta } from "~/pages/index.vue?macro=true";
export default [
  {
    name: "legal",
    path: "/:legal()",
    meta: _91legal_93IkzzjCWHDbMeta || {},
    component: () => import("/app/pages/[legal].vue").then(m => m.default || m)
  },
  {
    name: admin0eiku7Jj1aMeta?.name,
    path: "/admin",
    meta: admin0eiku7Jj1aMeta || {},
    component: () => import("/app/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-angebot-slug",
    path: "angebot/:slug()",
    meta: _91slug_93jOEkUlxx4DMeta || {},
    component: () => import("/app/pages/admin/angebot/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-angebote",
    path: "angebote",
    component: () => import("/app/pages/admin/angebote.vue").then(m => m.default || m)
  },
  {
    name: "admin-angebots-buchungen",
    path: "angebots-buchungen",
    component: () => import("/app/pages/admin/angebots-buchungen.vue").then(m => m.default || m)
  },
  {
    name: "admin-angebots-termine-slug",
    path: "angebots-termine/:slug()",
    meta: _91slug_93Cl40UnF9PfMeta || {},
    component: () => import("/app/pages/admin/angebots-termine/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-angebotsarten-und-kunstformen",
    path: "angebotsarten-und-kunstformen",
    component: () => import("/app/pages/admin/angebotsarten-und-kunstformen.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "",
    component: () => import("/app/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-kuenstler-in-slug",
    path: "kuenstler-in/:slug()",
    meta: _91slug_93doGLKyNMOsMeta || {},
    component: () => import("/app/pages/admin/kuenstler-in/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-kuenstler-innen",
    path: "kuenstler-innen",
    component: () => import("/app/pages/admin/kuenstler-innen.vue").then(m => m.default || m)
  },
  {
    name: "admin-kunstraeume",
    path: "kunstraeume",
    component: () => import("/app/pages/admin/kunstraeume.vue").then(m => m.default || m)
  },
  {
    name: "admin-kunstraum-slug",
    path: "kunstraum/:slug()",
    meta: _91slug_937AHS1VyE0lMeta || {},
    component: () => import("/app/pages/admin/kunstraum/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-landing-type",
    path: "landing/:type()",
    meta: _91type_934VbJNUA5cqMeta || {},
    component: () => import("/app/pages/admin/landing/[type].vue").then(m => m.default || m)
  },
  {
    name: "admin-medien",
    path: "medien",
    component: () => import("/app/pages/admin/medien.vue").then(m => m.default || m)
  },
  {
    name: "admin-region-slug",
    path: "region/:slug()",
    meta: _91slug_93iqyPrFmYbrMeta || {},
    component: () => import("/app/pages/admin/region/[slug].vue").then(m => m.default || m)
  },
  {
    name: "admin-regionen",
    path: "regionen",
    component: () => import("/app/pages/admin/regionen.vue").then(m => m.default || m)
  },
  {
    name: "admin-termin-buchungen-id",
    path: "termin-buchungen/:id()",
    component: () => import("/app/pages/admin/termin-buchungen/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "angebot-slug",
    path: "/angebot/:slug()",
    meta: _91slug_93qSYkVjzTIjMeta || {},
    component: () => import("/app/pages/angebot/[slug].vue").then(m => m.default || m)
  },
  {
    name: "anmelden",
    path: "/anmelden",
    meta: anmelden1zhaVHyhM6Meta || {},
    component: () => import("/app/pages/anmelden.vue").then(m => m.default || m)
  },
  {
    name: "buchung",
    path: "/buchung",
    meta: indexg58NvgAgO8Meta || {},
    component: () => import("/app/pages/buchung/index.vue").then(m => m.default || m)
  },
  {
    name: "buchung-uebersicht",
    path: "/buchung/uebersicht",
    meta: uebersicht5rdJ55PmfWMeta || {},
    component: () => import("/app/pages/buchung/uebersicht.vue").then(m => m.default || m)
  },
  {
    name: "danke",
    path: "/danke",
    meta: dankeO4KVv6EOejMeta || {},
    component: () => import("/app/pages/danke.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kitchensink",
    path: "/kitchensink",
    meta: kitchensinkthR9MqYzRIMeta || {},
    component: () => import("/app/pages/kitchensink.vue").then(m => m.default || m)
  },
  {
    name: "kuenstler-in-slug",
    path: "/kuenstler-in/:slug()",
    meta: _91slug_93ulmtor5kH4Meta || {},
    component: () => import("/app/pages/kuenstler-in/[slug].vue").then(m => m.default || m)
  },
  {
    name: "kunstraum-slug",
    path: "/kunstraum/:slug()",
    meta: _91slug_935HwWR9igluMeta || {},
    component: () => import("/app/pages/kunstraum/[slug].vue").then(m => m.default || m)
  },
  {
    name: "region-slug",
    path: "/region/:slug()",
    meta: _91slug_93G8e6QOqUrbMeta || {},
    component: () => import("/app/pages/region/[slug].vue").then(m => m.default || m)
  },
  {
    name: "index-region",
    path: "/regionen",
    meta: indexp9V2p5fxycMeta || {},
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index-location",
    path: "/kunstszene",
    meta: {"authType":"public","layoutProps":{"solidHeader":true}},
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index-artist",
    path: "/kuenstler-innen",
    meta: {"authType":"public","layoutProps":{"solidHeader":true}},
    component: () => import("~/pages/index.vue").then(m => m.default || m)
  }
]