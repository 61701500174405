import { useQuery } from '@tanstack/vue-query'

export type Region = NonNullable<Awaited<ReturnType<typeof fetchRegionBySlug>>['data']>

export function useRegionBySlug(slugId: MaybeRef<string>) {
  return useQuery({
    queryKey: ['region', slugId] as const,
    queryFn: async ({ queryKey }) =>
      fetchRegionBySlug(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}
export function fetchRegionBySlug(slugId: string) {
  const supabase = useSupabase()

  return supabase
    .from('region')
    .select('*, cms_page_section(*), address(*)')
    .eq('slug_id', slugId)
    .order('order', {
      ascending: true,
      referencedTable: 'cms_page_section',
    })
    .single()
}

export function useRegionPreviews() {
  const supabase = useSupabase()

  return useQuery({
    queryKey: ['region-overview'],
    queryFn: async () =>
      supabase
        .from('region')
        .select('id, name, image_url, slug, slug_id')
        .order('name')
        .throwOnError()
        .then((res) => res.data),
  })
}
