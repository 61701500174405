import { P, match } from 'ts-pattern'

import type { RouteLocationNormalized } from '#vue-router'
import type { User } from '@supabase/supabase-js'

export async function checkUserRoutePermissions(to: RouteLocationNormalized, user: User | null) {
  if (to.matched.length === 0) return

  const loggedIn = !!user
  const isAdmin = user?.user_metadata.role === 'admin'
  const authType = to.meta.authType || 'admin'
  const navigateGuestsTo = to.meta.navigateGuestsTo || 'anmelden'
  const navigateLoggedInTo = to.meta.navigateLoggedInTo || 'index'

  async function safeNavigate(
    toName: string,
    opts?: {
      addRedirectTo?: boolean
      useRedirectTo?: boolean
    },
  ) {
    if (opts?.useRedirectTo) {
      const path = to.query.redirectTo as string
      if (path && path !== to.path) {
        return navigateTo({
          path,
        })
      }
    }
    if (toName === to.name) return
    return navigateTo({
      name: toName,
      query: opts?.addRedirectTo ? { redirectTo: to.path, ...to.query } : to.query,
    })
  }

  return match({ type: authType, loggedIn, isAdmin })
    .with({ type: 'guest', loggedIn: true }, async () =>
      safeNavigate(navigateLoggedInTo, {
        useRedirectTo: true,
      }),
    )
    .with({ type: 'guest', loggedIn: false }, () => undefined)
    .with({ type: 'public' }, () => undefined)
    .with({ type: P.union('private', 'admin'), loggedIn: false }, async () =>
      safeNavigate(navigateGuestsTo, {
        addRedirectTo: true,
      }),
    )
    .with({ type: 'private', loggedIn: true }, () => undefined)
    .with({ type: P.not('guest'), isAdmin: true }, () => undefined)
    .with({ type: 'admin', isAdmin: false }, async () => safeNavigate(navigateGuestsTo))
    .exhaustive()
}
