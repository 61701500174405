import rich_json_vCKq1YMdLh from "/app/plugins/rich-json.ts";
import revive_payload_client_FT75H6GTi5 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_idcKuilgAm from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CYWg5Vfshw from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_pckkHztUKi from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_sass@1.7_rxap4ajuwzgi7dzrbif2taoj2i/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import supabase_client_xN1bvT0pFK from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.3.5/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import sentry_client_Ub7jFNgAqV from "/app/modules/sentry/runtime/sentry.client.ts";
import trpc_0KeCZOBKLF from "/app/plugins/trpc.ts";
import payload_client_oukw5KZwRZ from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_MDQ9ylLa2A from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_P1X4bU2Q2r from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wJ9SE0gL07 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_59O68PzUXW from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.20.0_typescript@5.5.4_vue@3.4.37_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_AR9GN8uJ9U from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.15_encoding@0.1.13_eslint@9.9.0_jiti@1.21_zqz6e7hmidjxekwfztwa6gauhm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_kqlybGgQEh from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.1_wp5onme56g7ka2gdnofntupslq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_80iVLIDlWm from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_sass@_mrkr2jms5ew7q45vwcrtrggon4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_mgAgXFs5cs from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_sass@_mrkr2jms5ew7q45vwcrtrggon4/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import slideovers_H7G5MzMgYC from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_s_4bqir2kfgka7swofaon625u6fa/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_Z1EDO0mxOQ from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_s_4bqir2kfgka7swofaon625u6fa/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_yqp40pupbz from "/app/node_modules/.pnpm/@nuxt+ui@2.18.4_axios@1.7.3_change-case@4.1.2_focus-trap@7.5.4_magicast@0.3.4_rollup@4.20.0_s_4bqir2kfgka7swofaon625u6fa/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_kMFpnxgd3E from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.2_magicast@0.3.4_rollup@4.20.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_iD6ukTkaAx from "/app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.15_sass@1.77.8_ter_f2cjtubd6x6x6kwnl7orv3aljq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import auth_client_4lHQFpoKNF from "/app/plugins/auth.client.ts";
import marker_io_client_3vX6FuptAi from "/app/plugins/marker-io.client.ts";
import query_Ux2Qt90xcP from "/app/plugins/query.ts";
import defaults_2tBOdn4wwD from "/app/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.18_h3@1.12.0_magicast@0.3.4_rollup@4.20.0_vite@5.4.0_@types+node@20.14.1_wp5onme56g7ka2gdnofntupslq/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  rich_json_vCKq1YMdLh,
  revive_payload_client_FT75H6GTi5,
  unhead_idcKuilgAm,
  router_CYWg5Vfshw,
  _0_siteConfig_pckkHztUKi,
  supabase_client_xN1bvT0pFK,
  sentry_client_Ub7jFNgAqV,
  trpc_0KeCZOBKLF,
  payload_client_oukw5KZwRZ,
  navigation_repaint_client_MDQ9ylLa2A,
  check_outdated_build_client_P1X4bU2Q2r,
  chunk_reload_client_wJ9SE0gL07,
  plugin_vue3_59O68PzUXW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AR9GN8uJ9U,
  titles_kqlybGgQEh,
  siteConfig_80iVLIDlWm,
  inferSeoMetaPlugin_mgAgXFs5cs,
  slideovers_H7G5MzMgYC,
  modals_Z1EDO0mxOQ,
  colors_yqp40pupbz,
  plugin_client_kMFpnxgd3E,
  plugin_iD6ukTkaAx,
  auth_client_4lHQFpoKNF,
  marker_io_client_3vX6FuptAi,
  query_Ux2Qt90xcP,
  defaults_2tBOdn4wwD
]