import { useQuery } from '@tanstack/vue-query'
import { type Result, err, ok } from 'neverthrow'

import type { Enums } from '~/supabase.db'

import type { RouteLocationNormalized } from '#vue-router'

export * from './artist'
export * from './location'
export * from './offer'
export * from './region'

export type LandingPageType = Enums<'landing_page_type'>

export function useSlugId(to?: RouteLocationNormalized) {
  return computed<Result<string, Error>>(() => {
    const route = to || useRoute()
    const slugParam = route.params.slug

    if (typeof slugParam !== 'string') return err(new Error('No slug param in URL'))

    return ok(slugParam.split('-').at(-1)!)
  })
}

export function useLandingPageByType(type: MaybeRef<LandingPageType>) {
  return useQuery({
    queryKey: ['landing_page', type] as const,
    queryFn: async ({ queryKey }) =>
      fetchLandingPageByType(queryKey[1])
        .throwOnError()
        .then((res) => res.data),
  })
}

export function fetchLandingPageByType(type: LandingPageType) {
  const supabase = useSupabase()
  return supabase
    .from('landing_page')
    .select('*, cms_page_section(*)')
    .eq('type', type)
    .order('order', {
      ascending: true,
      referencedTable: 'cms_page_section',
    })
    .single()
}

export function useArtFormPreviews() {
  const supabase = useSupabase()
  return useQuery({
    queryKey: ['art-form-overview'],
    queryFn: async () =>
      supabase
        .from('art_form')
        .select('id, name')
        .order('name')
        .throwOnError()
        .then((res) => res.data),
  })
}
