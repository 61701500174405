import { useQueryClient } from '@tanstack/vue-query'
import { createDefu } from 'defu'
import slug from 'slugify'

import type { RouteLocationNormalized } from '#vue-router'
import type { PostgrestSingleResponse } from '@supabase/supabase-js'
import type { DateTime } from 'luxon'

export function slugify(str: string) {
  return slug(str, {
    lower: true,
    strict: true,
    trim: true,
  })
}

export function validateAndCacheEntity<
  T extends (slugId: string) => PromiseLike<PostgrestSingleResponse<Record<string, unknown>>>,
>(label: string, fetchFromDb: T) {
  return async (to: RouteLocationNormalized): Promise<boolean> => {
    const slugId = useSlugId(to).value
    if (slugId.isErr()) return false

    const queryClient = useQueryClient() // use before await to use current context
    const entity = await fetchFromDb(slugId.value).then((res) => res.data)
    if (!entity) return false

    queryClient.setQueryData([label, slugId.value], entity)
    return true
  }
}

export function formatCurrency(cents: number) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
  }).format(cents / 100)
}

export function formatDateTime(date: DateTime) {
  return date.toFormat("dd.MM.yyyy — HH:mm 'Uhr'")
}

const storageObjectIdRegex = /-?\S{5}$/
export function formatStorageObjectName(name: string) {
  return name.replace(storageObjectIdRegex, '')
}

export function removeFileExtension(name: string) {
  return name.replace(/\.[^.]+$/, '')
}

// default defu but don't merge arrays
// eslint-disable-next-line @shopify/prefer-early-return
export const mergeComponentDefaultProps = createDefu((obj, key, value) => {
  if (Array.isArray(obj[key])) {
    obj[key] = value
    return true
  }
})

export function minmax(min: number, max: number, value: number) {
  return Math.min(Math.max(value, min), max)
}

type Exact<T, U extends T> = {
  [Key in keyof U]: Key extends keyof T
    ? U[Key] extends Date
      ? U[Key]
      : U[Key] extends object
        ? Exact<T[Key], U[Key]>
        : U[Key]
    : never
}

export function exact<T>() {
  return <O extends T>(o: Exact<T, O>) => o
}
